body {
  background-color: #18191A;
  min-height: 95vh;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

body::-webkit-scrollbar {
  display: none;
}

.App {
  text-align: center;
}

.navbar {
  display: flex;
  background-color: #232931;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  bottom: 1rem;
  width: 70%;
  height: 4rem;
  border-radius: 50px;
  z-index: 100;
}

.navbar > .link {  
  color: #F9F6EE;
  margin-bottom: -5px; /* Fixing alignment */
}

/* Setting custom dimensions for correct alignment */

.navbar > .link:nth-child(1) > svg { 
  width: 50px;
  height: 50px;
}

.navbar > .link:nth-child(2) > svg { 
  width: 44px;
  height: 44px;
}

.navbar > .link:nth-child(3) > svg { 
  width: 47px;
  height: 47px;
}

.navbar > .link:nth-child(4) > svg { 
  width: 42px;
  height: 42px;
}

/* end of custom dimensions */

.homepage, .about {
  color: whitesmoke;
}

.typeAnimation {
  font-size: 2rem;
}

.projects {
  display: flex;
  flex-wrap: wrap;
  gap: 10%;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1600px;
  margin-bottom: 7rem;
}

.contact {
  list-style: none;
  padding: 0;
  color: whitesmoke;
  margin-bottom: 6rem;
}

.contact > li {
  margin-bottom: 2rem;
  font-size: 4rem;
  letter-spacing: 0.4rem;
}

.contact > li:last-child {
  font-size: 2.5rem;
}

.contact > li > a:not(:last-child) {
  cursor: pointer;
}

.contact > li > a {
  color: whitesmoke;
}

.contact_icon {
  margin-right: 1rem;
}

h1 {
  letter-spacing: 0.6rem;
}

h2 {
  font-style: italic;
  font-size: 2.3rem;
  font-family: Georgia, serif, Verdana;
}

p {
  font-size: 1.4rem;
  max-width: 800px;
  letter-spacing: 0.2rem;
  line-height: 2.5rem;
  margin: 5rem 5rem 6rem 5rem;
  font-family: "Helvetica", "sans-serif";
}

a {
  text-decoration: none;
}

@media (max-width: 700px) {
  .contact > li {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    letter-spacing: 0.2rem;
  }

  .contact > li:last-child {
    font-size: 1.5rem;
  }

  p {
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
    line-height: 2.1rem;
    margin: 2rem 1rem 6rem 1rem;
  }
}

@media (max-width: 420px) {
  .typeAnimation {
    font-size: 1.7rem;
  }

  .navbar {
    width: 80%;
    height: 4.2rem;
  }
}

@media (max-width: 350px) {
  .typeAnimation {
    font-size: 1.5rem;
  }

  .navbar {
    width: 90%;
    height: 4.5rem;
  }

  .contact > li:last-child {
    font-size: 1.2rem;
  }

  svg {
    width: 40px;
    height: 40px;
  }
}