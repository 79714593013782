.card {
  height: 520px;
  width: 300px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  background-color: #18191A;
  text-align: center;
  font-size: 30px;
  margin-top: 2rem;
  box-shadow: #F9F6EE 0px 0px 10px;
}

.img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.description {
  font-size: 1.3rem;
  margin: 0 1rem 0.5rem 1rem;
  color: whitesmoke;
  font-family: Helvetica, sans-serif;
  line-height: 1.7rem;
}

.stack {
  display: flex;
  position: absolute;
  bottom: 17%;
  width: 100%;
  padding-top: 0.4rem;
  justify-content: space-evenly;
  flex-wrap: wrap;
  background-color: #232931;
  color: #F9F6EE;
}

.stack-inner > svg {
  width: 35px;
  height: 35px;
}

.links {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.links-inner {
  font-size: 1.2rem;
  color: #F9F6EE;
  border-radius: 50px;
  background-color: #232931;
  padding: 0.5rem 0.7rem 0.5rem 0.7rem;
  cursor: pointer;
}

@media (max-width: 420px) {
  .stack-inner > svg {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 350px) {
  .card {
    width: 270px;
  }
}